<template>
    <div class="AboutUs">
        <!-- <bread-link></bread-link> -->
        <div class="banner1">
            <div class="title-box">
                <div class="ch-text">国企改革</div>
            </div>
        </div>
        <div class="warpper">
            <div class="menu-box">
                <ul class="menu">
                    <li :class="activeType == item.type ? 'active' : ''" v-for="(item, index) in infoData" :key="index" @click="getListByCode(item.type)">{{ item.name }}</li>
                </ul>
            </div>
            <div class="content">
                <div class="list" v-for="(item, index) in dataList" :key="index" @click="readDetails('news',item)">
                    <div class="img-box">
                        <img :src="item.img" width="240" height="180" alt="" />
                    </div>
                    <div class="main">
                        <div class="title" :title="item.title">{{item.title}}</div>
                        <div class="desc"  :title="item.introduction">
                            {{ common.splitStr(item.introduction, 80) }}
                        </div>
                        <div class="date">{{ moment(item.publishDate || item.createTime).format('YYYY-MM-DD') }}</div>
                    </div>
                </div>

                <a-spin class="loading" size="large" v-if="loading" />
            </div>
            <div class="btn-box">
                <span class="load-more" v-if="!loading && pageCount > dataList.length" @click="loadMore()">加载更多</span>
            </div>
        </div>
    </div>
</template>

<script>
//页面引入swiper
import { queryGwNewsPage } from '@/api/basic'
import moment from 'moment'

export default {
    name: 'Industry',
    data() {
        return {
            moment,
            infoData: [
                { name: '公司要闻', type: 1 },
                { name: '行业聚焦', type: 2 },
                { name: '公示公告', type: 3 }
            ],
            dataList: [],
            activeType: 1,
            loading: false,
            searchParams: {
                type: '',
                page: 1,
                rows: 10
            },
            pageCount: 0
        }
    },
    mounted() {
        this.init()
    },
    methods: {
        init() {
            let acType = this.$route.params.type
            this.getListByCode(acType ? acType : this.activeType)
        },
        getListByCode(type) {
            this.activeType = type
            this.searchParams.page = 1
            this.loading = true
            this.searchParams.type = type
           
            queryGwNewsPage(this.searchParams).then(res => {
                this.dataList = res.data.data
                this.pageCount = res.data.count
                this.loading = false
            })
        },
        loadMore() {
            this.searchParams.page++
            queryGwNewsPage(this.searchParams).then(res => {
                this.dataList = this.dataList.concat(res.data.data)
                this.pageCount = res.data.count
                this.loading = false
            })
        },
        readDetails(type,item){
            if(item.url){
                window.open(item.url)
            }else{
                let id = item.id
                this.$router.push({ name: 'Details',params:{type:type,id:id} })
            }
        }
    }
}
</script>
<style lang="less" scoped>
.AboutUs {
    background: #f5f5f5;
    .banner1 {
        width: 100%;
        height: 680px;
        background: url(../../../assets/images/CompanyReform/bg.png) 50% 50% no-repeat;
        position: relative;
        .title-box {
            width: 1300px;
            text-align: center;
            position: absolute;
            left: 50%;
            transform: translate(-50%, 50%);
            color: #fff;
            .en-text {
                font-size: 60px;
            }
            .ch-text {
                font-size: 50px;
                margin-top: 40px;
                position: relative;
                padding-bottom: 20px;
                position: relative;
                font-weight: bold;
            }
            .ch-text:before {
                content: '';
                width: 40px;
                height: 5px;
                background: #fff;
                position: absolute;
                bottom: 0;
                left: 50%;
                margin-left: -20px;
            }
        }
    }
    .warpper {
        width: 1300px;
        margin: 0 auto;
        margin-top: 100px;
        padding: 0 0 50px 0;
        position: relative;
    }
    .menu-box {
        display: flex;
        .menu {
            width: 430px;
            margin: 0 auto;
            display: flex;
            justify-content: space-between;
            li {
                width: 130px;
                height: 50px;
                line-height: 50px;
                text-align: center;
                color: #333;
                background: #fff;
                box-sizing: border-box;
                cursor: pointer;
                border: 1px solid #e8e8e8;
            }
            li.active {
                background: #ff721d;
                color: #fff;
            }
        }
    }
    .content {
        margin-top: 80px;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        .list {
            width: 620px;
            margin: 10px 10px;
            background: #fff;
            display: flex;
            justify-content: space-between;
            position: relative;
            padding: 30px 20px;
            cursor: pointer;
            .img-box {
                width: 240px;
                height: 180px;
                .deep-img {
                    width: 200px;
                    height: 200px;
                    position: absolute;
                    display: block;
                    bottom: -45px;
                    left: -50px;
                    opacity: 0.5;
                    z-index: 1;
                }
                .deep-img-right {
                    position: absolute;
                    bottom: -45px;
                    right: -50px;
                    opacity: 0.5;
                    z-index: 1;
                }
            }
            .main {
                width: 350px;
                .title {
                    width: 100%;
                    font-size: 18px;
                    font-weight: 400;
                    color: #333333;
                    position: relative;
                    font-weight: bold;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    margin: 0;
                    padding-left: 15px;
                }
                .desc {
                    margin-top: 20px;
                    color: #666666;
                    padding-left: 15px;
                    height: 96px;
                    font-size: 14px;
                }
                .date {
                    padding-left: 15px;
                    font-size: 14px;
                }
            }
        }
    }
    .btn-box {
        text-align: center;
        margin-top: 30px;
        .load-more {
            display: inline-block;
            margin-top: 40px;
            color: #999999;
            border: 1px solid #999999;
            font-size: 14px;
            padding: 5px 10px;
            cursor: pointer;
            border-radius: 3px;
        }
        .load-more:hover {
            color: #ff721d;
            border-color: #ff721d;
        }
    }
    .loading {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}
</style>
